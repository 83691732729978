import { render, staticRenderFns } from "./RiddlePage.vue?vue&type=template&id=0d20fea5&scoped=true&"
import script from "./RiddlePage.vue?vue&type=script&lang=js&"
export * from "./RiddlePage.vue?vue&type=script&lang=js&"
import style0 from "./RiddlePage.vue?vue&type=style&index=0&id=0d20fea5&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0d20fea5",
  null
  
)

export default component.exports