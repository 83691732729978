import { checkAdminHash, getAdminHash } from "@/utils";

const isSessionStorageAvailable = () => {
    var test = 'test';
    try {
        sessionStorage.setItem(test, test);
        sessionStorage.removeItem(test);
        return true;
    } catch (e) {
        return false;
    }
}

const retrieveAdminHash = () => {
    return sessionStorage.getItem("adminHash");
}

const storeAdminHash = (hash) => {
    sessionStorage.setItem("adminHash", hash);
}


export default function createPersistencePlugin() {
    if (!isSessionStorageAvailable())
        return () => { };

    return (store) => {
        const adminHash = retrieveAdminHash();

        if (adminHash && checkAdminHash(adminHash)) store.commit("unlockAdmin");

        store.subscribe((mutation) => {
            if (mutation.type === "unlockAdmin") {
                storeAdminHash(getAdminHash());
            }
        });
    }
}