export const loginCredentials = {
    pin: "2915"
}

export const riddleCredentials = {
    firstname: "Alexandre",
    lastname: "Facile",
    address: "11 rue de la chouette"
}

export const adminCredentials = {
    hash: "854dc4d86ad2c8b8349f19a96822543f0d020a1a9923ac5237f9f5fd9bb6d502"
}