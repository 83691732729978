<template>
  <div class="admin-container">
    <div class="admin-wrapper">
      <v-text-field
        type="password"
        v-model="password"
        @keypress.enter="tryAdminLogin"
        autofocus
        solo
        append-icon="mdi-arrow-right"
        @click:append="tryAdminLogin"
        :error-messages="errMessage"
      >
        <template #message>
          <span class="admin-error">{{ errMessage }}</span>
        </template>
      </v-text-field>
    </div>
  </div>
</template>
<style scoped>
.admin-container {
  height: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.admin-wrapper {
  display: flex;
  align-items: center;
  justify-items: center;
}
</style>
<script>
export default {
  name: "AdminPage",
  data() {
    return {
      password: "",
    };
  },
  computed: {
    isAdminLogged: function () {
      return this.$store.state.adminLogged;
    },
    isError: function () {
      return this.$store.state.adminError;
    },
    errMessage: function () {
      return this.isError ? "Mot de passe incorrect" : "";
    },
  },
  methods: {
    tryAdminLogin: function () {
      this.$store.dispatch("tryAdminLogin", this.password);
    },
    gotoLogin: function () {
      this.$router.push("login");
    },
  },
  watch: {
    isAdminLogged: function (newValue) {
      if (newValue) this.gotoLogin();
    },
  },
  mounted() {
    if (this.isAdminLogged) this.gotoLogin();
  },
};
</script>
<style scoped>
.admin-container {
  background-image: url(../assets/back_cec.png);
  background-size: 250px;
  background-position: left 30px;
}

.admin-error {
  color: #ffffff;
}
</style>
