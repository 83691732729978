<template>
  <div class="alert-overlay" @click="closeAlert">
    <div class="alert-container" @click.stop>
      <div class="alert-title" v-html="message"></div>
      <div class="alert-button" @click="closeAlert">Suivant</div>
    </div>
  </div>
</template>
<script>
export default {
  name: "SimpleAlert",
  props: {
    message: {
      type: String,
      default: "",
    },
  },
  methods: {
    closeAlert: function () {
      this.$emit("close");
    },
  },
};
</script>
<style scoped>
.alert-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000004d;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alert-container {
  position: relative;
  max-width: 60%;
  background: #ff6445;
  border-radius: 57.5089px;
  border: 20.8684px solid #ffffff;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
}

.alert-title {
  font-family: Roboto;
  font-size: 56px;
  font-weight: 500;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  margin: 40px 45px 35px;
}

.alert-button {
  background: #ffffff;
  border: 2.68186px solid #fc421d;
  box-shadow: 0px 5.33459px 5.33459px rgba(0, 0, 0, 0.25);
  border-radius: 44.9211px;
  padding: 6.70464px 35.5346px;
  font-family: Roboto;
  font-size: 33px;
  font-weight: 500;
  line-height: 39px;
  letter-spacing: 0em;
  text-align: center;
  color: #ff6445;
  cursor: pointer;
}

.alert-button:hover {
  background-color: #ff6445;
  color: #ffffff;
  border-color: #ffffff;
}
</style>
