import Vue from 'vue'
import Router from 'vue-router'
import AdminPage from "../views/AdminPage.vue"
import LoginPage from "../views/LoginPage.vue";
import RiddlePage from "../views/RiddlePage.vue";
import CongratsPage from "../views/CongratsPage.vue"
import store from '@/store';

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: '/',
  routes: [
    {
      path: '/',
      name: 'admin',
      component: AdminPage,
      alias: '/admin'
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: { requiresAdmin: true }
    },
    {
      path: '/riddle',
      name: 'riddle',
      component: RiddlePage,
      meta: { requiresLogin: true }
    },
    {
      path: '/congrats',
      name: 'congrats',
      component: CongratsPage,
      meta: { requiresRiddle: true }
    },
  ]
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAdmin) && !store.state.adminLogged) {
    next("/admin")
  } else if (to.matched.some(record => record.meta.requiresLogin) && !store.state.loggedIn) {
    next("/login")
  } else if (to.matched.some(record => record.meta.requiresRiddle) && !store.state.riddleSolved) {
    next("/riddle")
  }
  else {
    next()
  }
})

export default router;