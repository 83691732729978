import { adminCredentials, loginCredentials, riddleCredentials } from "./credentials";
import sha256 from 'crypto-js/sha256';

const LONG_ANSWER_THRESHOLD = 15;

const normalizeString = function (s) {
    return s.normalize('NFD').replace(/\p{Diacritic}/gu, "").toLowerCase().trim();
}

export const adminAuthenticate = function (password) {
    const hash = sha256(password);
    console.log("hash: ", hash.toString());
    return hash.toString() === adminCredentials.hash;
}

export const checkAdminHash = function (hash) {
    return hash === adminCredentials.hash;
}

export const getAdminHash = function () {
    return adminCredentials.hash;
}

export const authenticate = function (secret) {
    return secret === loginCredentials.pin;
}

export const solveRiddle = function (firstname, lastname, address) {
    const results = {
        firstname: false,
        lastname: false,
        address: false
    };

    results.firstname = normalizeString(firstname) === normalizeString(riddleCredentials.firstname);

    results.lastname = normalizeString(lastname) === normalizeString(riddleCredentials.lastname);

    results.address = normalizeString(address) === normalizeString(riddleCredentials.address);

    return results;
}

export const testAnswer = function (fieldName, value) {
    return normalizeString(value).indexOf(normalizeString(riddleCredentials[fieldName])) !== -1;
}

export const getAnswer = function (fieldName) {
    return riddleCredentials[fieldName]
}

export const isLongAnswer = function (fieldName) {
    return riddleCredentials[fieldName].length > LONG_ANSWER_THRESHOLD;
}