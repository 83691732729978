import Vue from 'vue';
import Vuex from 'vuex';
import { adminAuthenticate, authenticate, solveRiddle } from '@/utils';
import createPersistencePlugin from './plugins/persistencePlugin';

Vue.use(Vuex)

const persistencePlugin = createPersistencePlugin();

export default new Vuex.Store({
    plugins: [persistencePlugin],
    state: {
        adminLogged: false,
        adminError: false,
        loggedIn: false,
        loginError: false,
        riddleSolved: false,
        riddleResults: {}
    },
    mutations: {
        unlockAdmin: function (state) {
            state.adminLogged = true;
        },
        unlockLogin: function (state) {
            state.loggedIn = true;
        },
        setAdminError: function (state) {
            state.adminError = true;
        },
        resetAdminError: function (state) {
            state.adminError = false;
        },
        setLoginError: function (state) {
            state.loginError = true;
        },
        resetLoginError: function (state) {
            state.loginError = false;
        },
        unlockRiddle: function (state) {
            state.riddleSolved = true;
        },
        setRiddleResults: function (state, results) {
            state.riddleResults = results;

            if (!Object.values(results).includes(false))
                state.riddleSolved = true;
        },
    },
    actions: {
        tryAdminLogin: function ({ commit }, payload) {
            if (adminAuthenticate(payload)) {
                commit("resetAdminError");
                commit("unlockAdmin");
            } else
                commit("setAdminError");
        },
        tryLogin: function ({ commit }, payload) {
            if (authenticate(payload)) {
                commit("resetLoginError");
                commit("unlockLogin");
            }
            else
                commit("setLoginError");
        },
        trySolveRiddle: function ({ commit }, payload) {
            const { firstname, lastname, address } = payload;
            const results = solveRiddle(firstname, lastname, address);
            commit("setRiddleResults", results);
        }
    }
});