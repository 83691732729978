<template>
  <div class="dialog-overlay" @click="cancelDialog">
    <div class="dialog-container" @click.stop>
      <img src="../assets/dialog_eyes.png" class="dialog-eyes" />
      <div class="dialog-title">Quelle est ta réponse ?</div>
      <div class="dialog-input-container">
        <div class="dialog-input-wrapper">
          <input
            type="text"
            v-model="dialogValue"
            @keypress.enter="submitDialog"
            ref="dialogInput"
            placeholder="proposition"
          />
        </div>
        <div class="dialog-submit-wrapper" @click="submitDialog">Envoyer</div>
      </div>
      <div v-show="!isValid" class="dialog-error">
        <span>Ce n'est pas la bonne réponse</span>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "RiddleDialog",
  props: {
    defaultValue: {
      type: String,
      default: "",
    },
    isValid: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dialogValue: "",
    };
  },
  methods: {
    submitDialog: function () {
      this.$emit("submit", this.dialogValue);
    },
    cancelDialog: function () {
      this.$emit("cancel");
    },
  },
  mounted() {
    this.dialogValue = this.defaultValue;
    this.$refs.dialogInput.focus();
  },
};
</script>
<style scoped>
.dialog-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #0000004d;
  z-index: 30;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.dialog-container {
  position: relative;
  max-width: 60%;
  background: #ff6445;
  border-radius: 57.5089px;
  border: 20.8684px solid #ffffff;
  padding: 20px;
  display: grid;
  grid-template-columns: 250px 350px;
  grid-template-rows: 180px auto 50px;
}

.dialog-eyes {
  position: relative;
  top: -60px;
}

.dialog-title {
  font-family: Roboto;
  font-size: 56px;
  font-weight: 500;
  line-height: 66px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.dialog-input-container {
  display: flex;
  align-items: center;
  grid-column: span 2;
  justify-content: center;
}

.dialog-input-wrapper {
  border-radius: 61.2139px 0px 0px 61.2139px;
  background-color: #ffffff;
  height: 60px;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 0 30px;
}

.dialog-input-wrapper input {
  width: 100%;
  border: none;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  text-transform: uppercase;
}

.dialog-input-wrapper input:focus {
  outline: none;
}

.dialog-submit-wrapper {
  background: #f39c12;
  border-radius: 0px 61.2139px 61.2139px 0px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
  color: #ffffff;
  height: 60px;
  width: 150px;
  pointer-events: all;
  cursor: pointer;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;
}

.dialog-submit-wrapper:hover {
  background-color: #ffffff;
  color: #f39c12;
}

.dialog-error {
  grid-column: span 2;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  letter-spacing: 0em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dialog-error span {
  color: #ffffff;
}
</style>
