<template>
  <div class="home-container">
    <div class="home-wrapper">
      <img src="../assets/home_title.svg" class="home-title" />
      <div class="home-disclaimer">
        <p>
          Ta première épreuve pour devenir membre du C.E.C. consiste à te
          connecter à la page d’enquête.<br />
          Cherche autour de toi, tu trouveras sûrement des indices.
        </p>
      </div>
      <div class="home-keypad-container">
        <div class="home-keypad">
          <div class="home-logo"></div>
          <div class="home-input-wrapper">
            <div class="home-input">{{ inputCode }}</div>
            <v-btn icon small @click="clearPin" v-show="inputCode !== ''"
              ><v-icon>mdi-close-thick</v-icon></v-btn
            >
          </div>

          <div class="home-submit-container">
            <div class="home-key" @click="submitCode">
              <v-icon class="home-chevron">mdi-chevron-right</v-icon>
            </div>
          </div>
          <div class="home-error">
            <span v-show="isError">Ce n'est pas le bon code !</span>
          </div>
          <div
            v-for="keyIndex in 9"
            :key="`key-${keyIndex}`"
            class="home-key-container"
            :style="{ 'grid-column': ((keyIndex - 1) % 3) + 2 }"
            @click="inputDigit(keyIndex)"
          >
            <div class="home-key">
              {{ keyIndex }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "LoginPage",
  data() {
    return {
      inputCode: "",
      maxLength: 6,
    };
  },
  computed: {
    loggedIn: function () {
      return this.$store.state.loggedIn;
    },
    isError: function () {
      return this.$store.state.loginError;
    },
  },
  methods: {
    inputDigit: function (digit) {
      if (this.inputCode.length >= this.maxLength) return;
      this.inputCode += digit;
    },
    submitCode: function () {
      //window.alert(`Code entré : ${this.inputCode}`);
      this.$store.dispatch("tryLogin", this.inputCode);
    },
    gotoRiddle: function () {
      this.$router.push("riddle");
    },
    clearPin: function () {
      this.inputCode = "";
    },
    resetError: function () {
      this.$store.commit("resetLoginError");
    },
  },
  watch: {
    loggedIn: function (value) {
      if (value) this.gotoRiddle();
    },
    inputCode: function () {
      this.resetError();
    },
  },
};
</script>
<style scoped>
.home-container {
  /* min-height: 100vh; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  overflow: auto;
  flex: 1;
  background-image: url(../assets/back_cec.png);
  background-size: 250px;
  background-position: left 30px;
}

.home-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 100px 320px 50px;
  gap: 30px;
  flex: 1;
}

.home-title {
  max-width: 719px;
  width: 100%;
}

.home-disclaimer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
}

.home-keypad-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.home-logo {
  grid-column: 1;
  background-image: url(../assets/chrome_logo.svg);
  background-repeat: no-repeat;
  background-size: 43.5px;
  min-width: 50px;
  height: 100%;
  background-position: center;
}

.home-submit-container {
  display: flex;
  justify-content: center;
}

.home-input-wrapper {
  grid-column: 2 / span 3;
  width: 100%;
  display: flex;
  border-radius: 60px;
  background-color: #f0f0f0;
  height: 43.5px;
  align-items: center;
  padding: 0 10px;
}

.home-input {
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fc421d;
  flex: 1;
}

.home-submit-container .home-key {
  width: 43px;
  height: 43px;
}

.home-keypad {
  width: 466px;
  height: 392px;
  border: 1.67px solid black;
  border-radius: 60px;
  background-color: #ffffff;
  box-shadow: 0px 8.36582px 8.36582px rgba(0, 0, 0, 0.25);
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: 80px 20px repeat(3, auto);
  font-family: Roboto;
  font-size: 50px;
  font-weight: 500;
  line-height: 58px;
  letter-spacing: 0em;
  text-align: center;
  color: white;
  align-items: center;
  padding: 1%;
}

.home-key-container {
  display: flex;
  justify-content: center;
}

.home-key {
  border: 2px solid white;
  border-radius: 50%;
  background-color: #fc421d;
  filter: drop-shadow(0px 5.44591px 5.44591px rgba(0, 0, 0, 0.25));
  height: 70px;
  width: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  user-select: none;
}

.home-chevron {
  color: #ffffff;
}

.home-error {
  grid-column: 2 / span 3;
  color: #000000;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 800;
  line-height: 5px;
  letter-spacing: 0em;
  text-align: center;
}

.home-key:hover {
  border-color: #fc421d;
  background-color: #ffffff;
  color: #fc421d;
}

.home-key:hover .home-chevron {
  color: #fc421d;
}
</style>
