<template>
  <div class="riddle-container" @click="onFormClick">
    <div class="riddle-form-container">
      <RiddleForm
        :inputResults="riddleResults"
        @submit="trySolve"
        :showHints="showHints"
      ></RiddleForm>
      <SimpleAlert
        v-if="alert"
        message="Félicitations&nbsp;!<br />Tu y es presque..."
        @close="onAlertClose"
      >
      </SimpleAlert>
    </div>
  </div>
</template>
<script>
import RiddleForm from "../components/RiddleForm.vue";
import SimpleAlert from "@/components/SimpleAlert.vue";

export default {
  name: "RiddlePage",
  components: {
    RiddleForm,
    SimpleAlert,
  },
  data() {
    return {
      inputCode: "",
      maxLength: 6,
      showHints: false,
      alert: false,
    };
  },
  computed: {
    isSolved: function () {
      return this.$store.state.riddleSolved;
    },
    riddleResults: function () {
      return this.$store.state.riddleResults;
    },
  },
  methods: {
    inputDigit: function (digit) {
      if (this.inputCode.length >= this.maxLength) return;
      this.inputCode += digit;
    },
    submitCode: function () {
      window.alert(`Code entré : ${this.inputCode}`);
      this.inputCode = "";
    },
    trySolve: function (answers) {
      this.$store.dispatch("trySolveRiddle", answers);
    },
    onformSubmit: function (answers) {
      this.$store.dispatch("trySolve", answers);
    },
    gotoCongrats: function () {
      this.$router.push("congrats");
    },
    toggleHints: function () {
      this.showHints = !this.showHints;
    },
    onFormClick: function (event) {
      if (event.ctrlKey && event.shiftKey) this.toggleHints();
    },
    showAlert: function () {
      this.alert = true;
    },
    onAlertClose: function () {
      this.alert = false;
      this.gotoCongrats();
    },
  },
  watch: {
    isSolved: function (value) {
      if (value) {
        this.showAlert();
      }
    },
  },
};
</script>
<style scoped>
.riddle-container {
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-image: url(../assets/back_cec.png);
  background-size: 250px;
  background-position: left 30px;
}

.riddle-title {
  width: 50%;
  margin-top: 150px;
}

.riddle-disclaimer {
  font-family: Roboto;
  font-size: 18px;
  font-weight: 500;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: center;
  color: #ffffff;
  flex: 1;
}

.riddle-form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-items: center;
  padding: 50px;
  width: 70%;
  margin-bottom: 50px;
}
</style>
