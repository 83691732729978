<template>
  <v-app>
    <router-view />
  </v-app>
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
#app {
  background-image: url(./assets/back_padlock.png), url(./assets/back_eyes.png),
    url(./assets/back_chromebook.png), url(./assets/back_gdrive.png),
    url(./assets/back_magnifier.png), url(./assets/chrome_orange_background.png);
  /* background-image:  no-repeat;
  background-image:  no-repeat; */
  /* background-position: left top, left right;
  background-repeat: no-repeat, no-repeat; */
  background-size: 220px, 500px, 390px, 150px, 320px, cover;
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: right top, -200px 60%, left bottom, right center,
    right bottom;
  width: 100%;
  height: 100%;
}

@font-face {
  font-family: "Google Sans";
  font-style: normal;
  font-weight: normal;
  src: url(./fonts/ProductSans-Medium.woff) format("woff");
}
</style>
